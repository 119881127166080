import React from 'react';
import PropTypes from 'prop-types';
import { isAndroidDevice } from '@lib/Utils';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import { Message } from '../components';

/**
 * Error 500
 */
export const Error500 = (props) => {
  const {
    FLOW_V2_ERROR_500_ALERT_TITLE = localizedString('errorOccured'),
    FLOW_V2_ERROR_500_ALERT_DESCRIPTION = localizedString('errorOccured')
  } = process.env;
  return (
    <Message {...props} title={FLOW_V2_ERROR_500_ALERT_TITLE} issue>
      {parse(FLOW_V2_ERROR_500_ALERT_DESCRIPTION)}
    </Message>
  );
};

/**
 * Error 400
 */
export const Error400 = (props) => {
  const { onRetryAgain = () => null, ...restProps } = props;
  const {
    FLOW_V2_ERROR_400_ALERT_TITLE = localizedString('unstableInternet'),
    FLOW_V2_ERROR_400_ALERT_DESCRIPTION = localizedString('tryAgainToReattempt')
  } = process.env;
  return (
    <Message
      {...restProps}
      buttons={[
        {
          label: localizedString('tryAgain'),
          full: true,
          onClick: onRetryAgain
        }
      ]}
      title={FLOW_V2_ERROR_400_ALERT_TITLE}
      issue
    >
      {parse(FLOW_V2_ERROR_400_ALERT_DESCRIPTION)}
    </Message>
  );
};

Error400.propTypes = {
  onRetryAgain: PropTypes.func
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  return (
    <Message
      {...props}
      buttons={[
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => document.location.reload()
        },
        {
          label: localizedString('tryAgain'),
          shadow: true,
          onClick: () => document.location.reload()
        }
      ]}
      title={localizedString('connectionRrror')}
      issue
    >
      {localizedString('checkInternetConnection')}
    </Message>
  );
};

/**
 * Timeout
 */
export const Timeout = (props) => {
  return (
    <Message {...props} title={localizedString('oopsSessionExpired')} issue>
      {localizedString('pleaseWait')}
    </Message>
  );
};

/**
 * Device Exceptions
 * */

export const DeviceIncompatibleOpenChrome = ({ issue = true, ...restOfProps }) => {
  const {
    FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_ANDROID = localizedString(
      'openDifferentBrowserAndroid'
    ),
    FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS = localizedString('openDifferentBrowserIos'),
    FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_ANDROID = localizedString('tapProceedAndroid'),
    FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS = localizedString('tapProceedIos')
  } = process.env;

  return (
    <Message
      {...restOfProps}
      title={
        isAndroidDevice()
          ? FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_ANDROID
          : FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS
      }
      issue={issue}
    >
      {parse(
        isAndroidDevice()
          ? FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_ANDROID
          : FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS
      )}
    </Message>
  );
};

DeviceIncompatibleOpenChrome.propTypes = {
  issue: PropTypes.bool
};

/**
 * Locked Transaction URL
 */
export const LockedTransactionUrl = (props) => {
  const { message } = props;

  return (
    <Message
      {...props}
      title={localizedString('lockedTransactionUrl')}
      issue={localizedString('identityCaptureInProgress')}
    >
      <div>
        {localizedString('verifyYouDontHaveOtherOpenTabs')}
        {message}
      </div>
    </Message>
  );
};

LockedTransactionUrl.propTypes = {
  message: PropTypes.string
};
