import { localizedString } from '@languages';

import { noEmojies } from '@lib/constants/regex';

const { DL_NUMBER_VALIDATION = true } = process.env;

export const defaultLettersAndSymbolsRegexMatch = {
  message: localizedString('lettersAndSymbolsOnly'),
  regex: noEmojies
};

const defaultLettersAndNumbersRegexMatch = {
  message: localizedString('lettersAndNumbersOnly'),
  regex: /^([a-zA-Z0-9]+)$/
};

const maxLength50CharsValidation = {
  message: localizedString('max50Chars'),
  length: 50
};

const firstName = {
  isRequired: localizedString('pleaseEnterFirstName'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const middleName = {
  isRegexMatch: defaultLettersAndSymbolsRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const lastName = {
  isRequired: localizedString('pleaseEnterLastName'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const dateOfBirth = {
  isRequired: localizedString('pleaseEnterDateOfBirth'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const expiryDate = {
  isRequired: localizedString('pleaseEnterDateOfExpiry'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const visaStartDate = {
  isRequired: localizedString('pleaseEnterVisaStartDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const visaEndDate = {
  isRequired: localizedString('pleaseEnterVisaEndDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
};

const licenceNumber = {
  isRequired: localizedString('pleaseEnterLicenceNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMinLength: {
    message: localizedString('4CharactersMinimum'),
    length: 4
  },
  isExactLength: {
    message: localizedString('licenceNumberMustBe8Chars'),
    length: 8
  }
};

if (!DL_NUMBER_VALIDATION) {
  delete licenceNumber.isExactLength;
}

const licenceVersion = {
  isRequired: localizedString('pleaseEnterVersionNumber'),
  isRegexMatch: {
    message: localizedString('numbersOnly'),
    regex: /^([0-9]+)$/
  },
  isExactLength: {
    message: localizedString('versionNumberMustBe3Numbers'),
    length: 3
  }
};

const passportNumber = {
  isRequired: localizedString('pleaseEnterPassportNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: {
    message: localizedString('alphaNumeric9DigitsMaximum'),
    length: 9
  }
};

const cardNumber = {
  isRequired: localizedString('pleaseEnterCardNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: {
    message: localizedString('max10Chars'),
    length: 10
  }
};

const idNumber = {
  isRequired: localizedString('pleaseEnterIDNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const taxNumber = {
  isRequired: localizedString('pleaseEnterTaxNumber'),
  isRegexMatch: {
    message: localizedString('numbersDashAndDotsOnly'),
    regex: /^([0-9.-]+)$/
  },
  isMaxLength: maxLength50CharsValidation
};

const documentNumber = {
  isRequired: localizedString('pleaseEnterDocumentNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch,
  isMaxLength: maxLength50CharsValidation
};

const dlclassCodeBFrom = {
  isRequired: localizedString('pleaseEnterBClassIssueDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/.]+)$/
  }
};

export const formValidation = {
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  expiryDate,
  visaStartDate,
  visaEndDate,
  licenceNumber,
  licenceVersion,
  passportNumber,
  cardNumber,
  idNumber
};

export const fieldsValidation = {
  firstName: { firstName },
  lastName: { lastName },
  middleName: { middleName },
  dateOfBirth: { dateOfBirth },
  expiryDate: { expiryDate },
  visaStartDate: { visaStartDate },
  visaEndDate: { visaEndDate },
  licenceNumber: { licenceNumber },
  licenceVersion: { licenceVersion },
  passportNumber: { passportNumber },
  cardNumber: { cardNumber },
  idNumber: { idNumber },
  taxNumber: { taxNumber },
  documentNumber: { documentNumber },
  dlclassCodeBFrom: { dlclassCodeBFrom }
};
