import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Input } from '@lib/components/v2/Form';
import classNames from 'classnames';
import { localizedString } from '@languages';
import classes from './DetailMatch.style.module.scss';

const DetailMatch = ({ onChange, errors, dirty, submitted, ...restProps }) => {
  /**
   * Input field data.
   */

  const {
    DETAIL_MATCH_LABEL = false,
    DETAIL_MATCH = [],
    DETAIL_MATCH_CONTENT = null,
    APP_LOGO = ''
  } = process.env;
  const fields = DETAIL_MATCH.map((key) => {
    const value = restProps[key];
    return {
      id: key,
      label: DETAIL_MATCH_LABEL ? DETAIL_MATCH_LABEL[key] : '',
      value: value || ''
    };
  });

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field) => {
    const { id } = field;

    return (
      <Input
        key={id}
        className={classNames(classes.input)}
        hasError={dirty[id] || submitted ? errors[id] : null}
        onChange={(value) => onChange(id, value)}
        {...field}
      />
    );
  });

  const defaultContent = `${localizedString(
    'forProtectionVerifyThatYourApplication'
  )}<br /><br />${localizedString('enterDetailsBelow')}<br /><br />${localizedString(
    'enterNameAsPerApplication'
  )}`;

  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO}`;

  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      <img alt="" className="b-logo" src={finalLogo} />
      <br />
      <br />
      <div className={classes.title}>
        {DETAIL_MATCH_CONTENT ? parse(DETAIL_MATCH_CONTENT) : parse(defaultContent)}
      </div>
      <div className={classes.container}>{generateFields}</div>
    </div>
  );
};

DetailMatch.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.object,
  dirty: PropTypes.object,
  name: PropTypes.string,
  reference: PropTypes.string,
  submitted: PropTypes.bool
};

DetailMatch.defaultProps = {
  name: '',
  reference: '',
  onChange: () => {}
};

export default DetailMatch;
