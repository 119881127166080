import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import experianService from './experianService';
import addyService from './addyService';
import addressFinderService from './addressFinderService';

const BASE_URL = process.env.API_URL || '/api/v1';

const ADDRESS_SERVICES_IN_PRIORITY_ORDER = [experianService, addyService, addressFinderService];

export default {
  find,
  verify,
  formatManualAddress,
  isAddressAPIDisabled
};

async function getAvailableBackendServices() {
  try {
    const { data } = await axios.get(`${BASE_URL}/address/service`);
    return data.addressValidators;
  } catch (error) {
    console.error(error);
    throw new Error('Error trying to get available address services');
  }
}

async function getMatchedService(country) {
  if (isEmpty(country)) {
    throw new Error('No country specified');
  }

  const availableBackendServices = await getAvailableBackendServices();

  if (!isArray(availableBackendServices) || isEmpty(availableBackendServices)) {
    throw new Error('No available backend address services');
  }

  const addressService = ADDRESS_SERVICES_IN_PRIORITY_ORDER.find((service) => {
    return (
      service.canHandleCountry(country) && availableBackendServices.includes(service.getName())
    );
  });

  if (addressService) {
    return addressService;
  }

  throw new Error('No match between countries and/or available address services');
}

async function find(address, country, isExtracted) {
  try {
    const addressService = await getMatchedService(country);

    return addressService.find({ address, country, isExtracted });
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function verify(address, country, dataProvider, idHash) {
  try {
    const addressService = await getMatchedService(country);

    return addressService.verify({ address, country, dataProvider, idHash });
  } catch (error) {
    console.error(error);
    return getFallbackFormattedAddress(address, country);
  }
}

function getFallbackFormattedAddress(address, country) {
  const addressData = {
    fullAddress: address,
    addressLine1: '',
    addressLine2: '',
    unit_number: '',
    street_number: '',
    street_name: '',
    street_type: '',
    suburb: '',
    city: '',
    state_territory: '',
    postcode: '',
    country
  };

  return { matched: false, addressData };
}

function formatManualAddress(address) {
  const newData = {
    manual: true,
    fullAddress: '',
    addressLine1: address.addressLine1 || '',
    addressLine2: address.addressLine2 || '',
    unit_number: '',
    street_number: address.street_number || '',
    street_name: address.street_name || '',
    street_type: '',
    suburb: address.suburb || '',
    city: address.suburb || '',
    state_territory: address.state_territory || '',
    postcode: address.postcode || '',
    country: address.country || '',
    selectedManualCountryCode: address.selectedManualCountryCode || '',
    addressApiCalls: address.addressApiCalls || 0
  };

  if (newData.addressLine1 !== '') {
    newData.fullAddress += `${newData.addressLine1} `;
  }

  if (newData.addressLine2 !== '') {
    newData.fullAddress += `${newData.addressLine2} `;
  }

  if (newData.suburb !== '') {
    newData.fullAddress += `${newData.suburb} `;
  }

  if (newData.state_territory !== '') {
    newData.fullAddress += `${newData.state_territory} `;
  }

  if (newData.postcode !== '') {
    newData.fullAddress += newData.postcode;
  }

  return newData;
}

async function isAddressAPIDisabled(country) {
  try {
    const result = await getMatchedService(country);
    return isEmpty(result);
  } catch (error) {
    console.error(error);
    return true;
  }
}
