/* eslint-disable camelcase */
import axios from 'axios';

const BASE_URL = process.env.API_URL || '/api/v1';

export default {
  getName,
  find,
  verify,
  canHandleCountry
};

function getName() {
  return 'experian';
}

function canHandleCountry() {
  return true;
}

// TODO: put this validation in the Address Component  if (!address || (address && address.length <= 3)) {  console.warn('please enter at least 4 characters');

async function find({ address, isExtracted, country, detailed = false }) {
  const source = axios.CancelToken.source();

  window.addEventListener('offline', () => {
    source.cancel('Operation canceled by the user.');
  });

  if (!address || (address && address.length <= 3)) {
    return [];
  }

  const payload = {
    address,
    country
  };

  try {
    const { data } = await axios.post(`${BASE_URL}/address/autocomplete/${getName()}`, payload, {
      cancelToken: source.token
    });

    if (!data.result || !data.result.suggestions) {
      throw new Error('No results');
    }

    if (!isExtracted) {
      return data.result.suggestions;
    }

    if (!data.result.suggestions.length) {
      return [
        {
          text: ''
        }
      ];
    }

    const { global_address_key: idHash } = data.result.suggestions[0];

    const addressData = await validateExperian({ idHash });

    const { fullAddress } = addressData;
    if (detailed) {
      return {
        addressData,
        matched: true,
        provider: getName()
      };
    }
    return [
      {
        text: fullAddress,
        matched: true,
        provider: getName()
      }
    ];
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function verify({ address, country, idHash }) {
  if (idHash) {
    const addressData = await validateExperian({ idHash });

    return {
      addressData,
      matched: true,
      provider: getName()
    };
  }

  return find({ address, country, isExtracted: true, detailed: true });
}

async function validateExperian({ idHash }) {
  const source = axios.CancelToken.source();

  window.addEventListener('offline', () => {
    source.cancel('Operation canceled by the user.');
  });

  const payload = {
    idHash
  };

  try {
    const { data } = await axios.post(`${BASE_URL}/address/validate/${getName()}`, payload, {
      cancelToken: source.token
    });

    if (data.result && data.result.address) {
      return format(data.result);
    }
    return {};
  } catch (error) {
    console.error('resolved error exception', error);
    return {};
  }
}

function format(addressData) {
  const { address, components } = addressData;

  const formattedAddress = { ...address };

  let fullAddress = '';
  Object.keys(address).forEach((key) => {
    if (address[key] && key !== 'country') {
      fullAddress += `${address[key]} `;
    }
  });

  formattedAddress.fullAddress = fullAddress.trim();

  if ('building' in components) {
    const { building } = components;
    Object.keys(building).forEach((key) => {
      if (key.includes('building_')) {
        formattedAddress[key] = building[key];
      } else {
        formattedAddress[`building_${key}`] = building[key];
      }
    });
  }

  if ('sub_building' in components) {
    const { sub_building } = components;

    if ('door' in sub_building) {
      const { door } = sub_building;
      Object.keys(door).forEach((key) => {
        if (key === 'full_name') {
          formattedAddress.unit_door_full_number = door[key];
        } else {
          formattedAddress[`unit_door_${key}`] = door[key];
        }
      });
    }

    if ('floor' in sub_building) {
      const { floor } = sub_building;
      Object.keys(floor).forEach((key) => {
        if (key === 'full_name') {
          formattedAddress.unit_floor_full_number = floor[key];
        } else {
          formattedAddress[`unit_floor_${key}`] = floor[key];
        }
      });
    }

    if ('entrance' in sub_building) {
      const { entrance } = sub_building;
      Object.keys(entrance).forEach((key) => {
        if (key === 'full_name') {
          formattedAddress.unit_entrance_full_number = entrance[key];
        } else {
          formattedAddress[`unit_entrance_${key}`] = entrance[key];
        }
      });
    }
  }

  if ('street' in components) {
    const { street } = components;
    Object.keys(street).forEach((key) => {
      formattedAddress[`street_${key}`] = street[key];
    });
  }

  if ('locality' in components) {
    const { locality } = components;
    Object.keys(locality).forEach((key) => {
      formattedAddress[`locality_${key}_name`] = locality[key].name;
    });
  }

  formattedAddress.address_api = getName();
  return formattedAddress;
}
