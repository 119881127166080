import axios from 'axios';

const ADDRESS_FINDER_ALLOWED_COUNTRIES = ['AU', 'NZ'];
const BASE_URL = process.env.API_URL || '/api/v1';

export default {
  getName,
  find,
  verify,
  canHandleCountry
};

function getName() {
  return 'addressFinder';
}

function canHandleCountry(country) {
  return ADDRESS_FINDER_ALLOWED_COUNTRIES.includes(country);
}

async function find({ address, country }) {
  const payload = {
    address,
    country
  };

  const source = axios.CancelToken.source();

  try {
    const { data } = await axios.post(`${BASE_URL}/address/autocomplete/${getName()}`, payload, {
      cancelToken: source.token
    });
    return data.completions;
  } catch (err) {
    console.error(err);
    return [];
  }
}

async function verify({ address, country, dataProvider }) {
  const payload = {
    address
  };
  const source = axios.CancelToken.source();
  return axios
    .post(`${BASE_URL}/address/validate/${getName()}`, payload, { cancelToken: source.token })
    .then(({ data }) => {
      return format(address, country, dataProvider, data);
    })
    .catch((err) => {
      console.error(err);
      return format(address, country, dataProvider);
    });
}

function format(address, country, dataProvider = null, cleanseData = null) {
  let countryCode = country;
  if (country === 'AU') {
    countryCode = 'AUS';
  } else if (country === 'NZ') {
    countryCode = 'NZL';
  }

  let newData = {
    fullAddress: address,
    addressLine1: '',
    addressLine2: '',
    unit_number: '',
    street_number: '',
    street_name: '',
    street_type: '',
    suburb: '',
    city: '',
    state_territory: '',
    postcode: '',
    country: countryCode
  };
  let matched = false;

  // If the API call had exception or It's different country other than AU, NZ
  if (!cleanseData) {
    return { matched, addressData: newData };
  }

  // If it is Address Finder call and Address entered is not valid
  if (!cleanseData.matched) {
    return { matched, addressData: newData };
  }

  let { address: addrObject } = cleanseData;
  if (!addrObject) {
    addrObject = cleanseData;
  }

  if (dataProvider === 'centrix') {
    matched = true;
    newData = {
      fullAddress: address,
      addressLine1: addrObject.address_line_1 || '',
      addressLine2: addrObject.address_line_2 || '',
      city: addrObject.city || '',
      suburb: addrObject.suburb || '',
      postcode: addrObject.postcode || '',
      address_api: 'addressfinder'
    };

    if (country === 'AU') {
      newData.suburb = addrObject.locality_name;
    }
  } else {
    matched = true;
    // green ID or IDMatrix

    newData = {
      fullAddress: address,
      addressLine1: addrObject.address_line_1 || '',
      addressLine2: addrObject.address_line_2 || '',
      unit_number: addrObject.unit_identifier || '',
      street_number: addrObject.street_number_1 || '',
      street_name: addrObject.street_name || '',
      street_type: addrObject.street_type || '',
      suburb: addrObject.locality_name || '',
      city: addrObject.city || '',
      state_territory: addrObject.state_territory || '',
      postcode: addrObject.postcode || '',
      country: countryCode,
      address_api: 'addressfinder'
    };
  }

  return { matched, addressData: newData };
}
