/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, ButtonGroup, Button } from '@lib/components/v2/Form';

import Select, { components } from 'react-select';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import countries from '../../../data/countries.json';

import classes from './SendSMS.style.module.scss';

const SingleValue = ({ children, ...props }) => {
  return <components.SingleValue {...props}>{props.data.dial_code}</components.SingleValue>;
};

SingleValue.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};

const Menu = (props) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <div className="select-options">{children}</div>
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const Inputs = (props) => {
  return <components.Input {...props} className="select-input" />;
};
const Option = (props) => {
  const { children } = props;
  return (
    <components.Option {...props} className="option">
      {children}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node
};

class SendSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country_code_select: { name: 'New Zealand', dial_code: '+64', code: 'NZ' },
      phone_number: ''
    };
  }

  componentDidMount() {
    const { ipCountry, country } = this.props;
    const { phone, phone_code = null } = document.body.dataset;
    let country_code_select = { name: 'Australia', dial_code: '+61', code: 'AU' };

    if (ipCountry === 'NZ') {
      country_code_select = { name: 'New Zealand', dial_code: '+64', code: 'NZ' };
    }

    if (phone_code) {
      const found = countries.find((country) => {
        return country.dial_code === phone_code;
      });

      if (found) {
        country_code_select = found;
      }
    } else {
      country_code_select =
        country === 'NZ'
          ? { name: 'New Zealand', dial_code: '+64', code: 'NZ' }
          : { name: 'Australia', dial_code: '+61', code: 'AU' };
    }

    this.setState({
      phone_number: phone,
      phone_code: country_code_select.dial_code,
      country_code_select
    });

    const { onChange } = this.props;
    onChange({
      phone_number: phone,
      phone_code: country_code_select.dial_code
    });
  }

  handleChangeSelectBox = (value) => {
    const { onChange } = this.props;
    this.setState({
      country_code_select: value
    });

    const stateData = { ...this.state };
    stateData.phone_code = value.dial_code;
    onChange(stateData);
  };

  handleChange = (id, value) => {
    const { onChange } = this.props;
    this.setState({
      [id]: value
    });

    const stateData = { ...this.state };
    stateData[id] = value;
    stateData.phone_code = stateData.country_code_select.dial_code;

    onChange(stateData);
  };

  render() {
    const { errors, onCancel, onHelp, isLoading, resendCount } = this.props;
    const {
      APP_LOGO,
      DISABLE_COUNTRY_SELECT = false,
      SEND_SMS_BACK_BUTTON = true,
      SELECT_COUNTRIES = [],
      SHOW_DESKTOP_SMS = true,
      DESKTOP_SMS_DISABLED_CONTENT = null,
      DESKTOP_SMS_DISABLED_TITLE = null
    } = process.env;
    const { phone_number = '', country_code_select } = this.state;

    let filteredCountries = countries;
    if (SELECT_COUNTRIES.length > 0) {
      filteredCountries = countries.filter((country) => {
        return SELECT_COUNTRIES.includes(country.code);
      });
    }

    const logo = localStorage.getItem(`${window.location.host}_app_logo`);
    const finalLogo = logo || `images/logos/${APP_LOGO}`;

    const defaultContent = `<h5 className="text-black-50">
        ${localizedString('accessLinkSentToMobile')}!</h5>
        ${localizedString('accessLinkSentToMobile')}`;
    const defaultTitle = localizedString('identificationProcess');
    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <img alt="" className={classNames(classes.logo, 'b-logo')} src={finalLogo} />
          <div className={classes.block}>
            <div className={classes.title}>{DESKTOP_SMS_DISABLED_TITLE || defaultTitle}</div>

            {SHOW_DESKTOP_SMS && resendCount < 3 && (
              <div className={classes.content}>
                <p>
                  <b>{localizedString('weNeedToUseYourMobile')}</b>
                  <br />
                  <br />
                  {localizedString('enterMobileNumberAndSend1')}
                  <br />
                  {localizedString('enterMobileNumberAndSend2')}
                  <br />
                  <br />
                  <a target="_blank" rel="noopener noreferrer" href="" onClick={onHelp}>
                    {localizedString('notReceivedMessage')}
                  </a>
                </p>

                <div className={classes.input}>
                  <div className={classes.code}>
                    <Select
                      isDisabled={DISABLE_COUNTRY_SELECT}
                      options={filteredCountries}
                      onChange={this.handleChangeSelectBox}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `2px solid #c7c7c7`,
                          paddingBottom: '2px',
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          // This line disable the blue border
                          boxShadow: state.isFocused ? 0 : 0,
                          '&:hover': {
                            // border: state.isFocused ? 0 : 0
                          }
                        })
                      }}
                      components={{ SingleValue, Menu, Input: Inputs, Option }}
                      value={country_code_select}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0',
                        colors: {
                          ...theme.colors
                          // primary: '#5b8f23',
                        }
                      })}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.dial_code}
                      className="country_code_desktop"
                    />
                  </div>
                  <div className={classes.number}>
                    <Input
                      id="phone_number"
                      value={phone_number}
                      hasError={errors.phone_number}
                      placeholder="000 000 000"
                      onChange={(value) => this.handleChange('phone_number', value)}
                    />
                  </div>
                </div>

                {SEND_SMS_BACK_BUTTON && (
                  <ButtonGroup stacked btnCount="1">
                    <Button
                      label={localizedString('doneGoBack')}
                      variant="outline"
                      onClick={onCancel}
                    />{' '}
                    <Button loading={isLoading} type="submit" label={localizedString('send')} />
                  </ButtonGroup>
                )}

                {!SEND_SMS_BACK_BUTTON && (
                  <ButtonGroup>
                    <Button
                      full
                      loading={isLoading}
                      type="submit"
                      label={localizedString('send')}
                    />
                  </ButtonGroup>
                )}
              </div>
            )}

            {SHOW_DESKTOP_SMS && resendCount >= 3 && (
              <div className={classes.content}>
                <p>
                  <h4>{localizedString('exceedAmountOfSMSRetries')}</h4>
                  {localizedString('checkYourSMSMessages1')} <br />
                  {localizedString('checkYourSMSMessages2')}
                </p>
              </div>
            )}
            {!SHOW_DESKTOP_SMS && (
              <div className="p-4 text-center">
                {DESKTOP_SMS_DISABLED_CONTENT
                  ? parse(DESKTOP_SMS_DISABLED_CONTENT)
                  : parse(defaultContent)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SendSMS.propTypes = {
  errors: PropTypes.object,
  onCancel: PropTypes.func,
  onHelp: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  country: PropTypes.string,
  ipCountry: PropTypes.string,
  resendCount: PropTypes.number
};

SendSMS.defaultProps = {
  onCancel: () => null,
  onChange: () => null,
  onHelp: () => null
};

export default SendSMS;
