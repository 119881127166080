import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message } from '@FLOW_V2_FLOW/components';
import { localizedString } from '@languages';

/**
 * Error 500
 */

export class Error500 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNewSession: false
    };
  }

  render() {
    const { buttons, onTryAgain, ...remainingProps } = this.props;
    const { isNewSession = false } = this.state;

    return (
      <Message
        {...remainingProps}
        title={
          isNewSession ? localizedString('sessionCancelled') : localizedString('errorEncountered')
        }
        issue={
          isNewSession
            ? localizedString('thisSessionIsCancelled')
            : localizedString('errorOnOurSide')
        }
      >
        {!isNewSession && <div>{localizedString('pleaseRefreshThePage')}</div>}
      </Message>
    );
  }
}

Error500.propTypes = {
  buttons: PropTypes.object,
  onTryAgain: PropTypes.func
};

const InternetCutV1 = (props) => {
  return (
    <Message
      {...props}
      buttons={[
        {
          label: localizedString('reloadSession'),
          large: true,
          shadow: true,
          onClick: () => document.location.reload()
        }
      ]}
      title={localizedString('connectivityIssue')}
      issue={localizedString('connectivityIssueDesc')}
    >
      {localizedString('checkYourInternetConnection')}
      <br />
      <b>{localizedString('reloadSession_CheckInternetConnection')}</b>
      {localizedString('belowToContinue')}
    </Message>
  );
};

const InternetCutV2 = (props) => {
  const { isOnline, onGoBack } = props;

  return (
    <Message
      {...props}
      buttons={[
        {
          label: localizedString('continue'),
          large: true,
          shadow: true,
          onClick: () => {
            if (isOnline) {
              onGoBack();
            }
          }
        }
      ]}
      title={localizedString('connectivityIssue')}
      issue={localizedString('connectivityIssueDesc2')}
    >
      {localizedString('checkYourInternetConnection')}
      <br />
      <b>{localizedString('continue_CheckInternetConnection')}</b>
      {localizedString('belowToContinue')}
    </Message>
  );
};

InternetCutV2.propTypes = {
  isOnline: PropTypes.bool,
  onGoBack: PropTypes.func
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  const { INTERNET_CUT_VERSION = 0 } = process.env;
  const Components = [InternetCutV1, InternetCutV2];

  const Exception = Components[INTERNET_CUT_VERSION];
  return <Exception {...props} />;
};

/**
 * Timeout
 */
export const Timeout = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('timeout')}
      issue={localizedString('sessionHasTimedOut')}
    >
      {localizedString('newSessionToComplete1')}
      <br />
      <br />
      {localizedString('newSessionToComplete2')}
    </Message>
  );
};

/**
 * Device Exceptions V1
 * */

const DeviceIncompatibleOpenChromeV1 = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('deviceIncompatible')}
      issue={localizedString('browserNotCompatible')}
    >
      {localizedString('openDifferentBrowserToProceed')}
    </Message>
  );
};

/**
 * Device Exceptions V2
 * */

const DeviceIncompatibleOpenChromeV2 = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('deviceIncompatible')}
      issue={localizedString('willNeedDifferentBrowser')}
    >
      {localizedString('tapProceedToContinue')}
    </Message>
  );
};

export const DeviceIncompatibleOpenChrome = (props) => {
  const { DEVICE_INCOMPATIBLE_CHROME_VERSION = 0 } = process.env;
  const Components = [DeviceIncompatibleOpenChromeV1, DeviceIncompatibleOpenChromeV2];

  const Exception = Components[DEVICE_INCOMPATIBLE_CHROME_VERSION];
  return <Exception {...props} />;
};
